/**
 * Login form
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.admin.login {
	block-size: 100%;
	background-color: var(--color-primary-50);

	body {
		display: grid;
		place-items: center;
		padding: 1rem;
		block-size: 100%;
		box-sizing: border-box;
	}

	h1 {
		margin-block-start: 2rem;
	}

	button {
		inline-size: 100%;
	}

	.logo {
		svg {
			block-size: 2.5rem;
			inline-size: auto;

			@media (--medium-devices) {
				block-size: 3.125rem;
			}
		}
	}

	.card {
		border-radius: 1.25rem;
		inline-size: 100%;
		max-inline-size: 28rem;
		box-sizing: border-box;

		@media (--medium-devices) {
			padding: 3.75rem;
		}
	}

	.form-list {
		margin-block-start: 0.75rem;
	}
}
