/**
 * Consent Request
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.consent-request {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	margin: 2.5rem 0 0;
	padding: 2rem 1rem;
	border: 1px solid var(--color-grey-300);
	text-align: center;
	color: var(--color-text);
	background: var(--color-grey-100);
	box-sizing: border-box;

	.consent-toggle {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-block-end: 1rem;
	}

	.consent-disclaimer {
		max-block-size: 100%;
		overflow: auto;
		font-size: 0.75em;
	}
}
