/**
 * Columns
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.columns {
	display: flex;
	flex-direction: column;

	&.columns-third {
		.column:last-child {
			margin: 2.5rem -1rem 0;
			padding: 2.5rem 1rem;
			inline-size: auto;
			background-color: var(--color-grey-100);
		}
	}

	.contact & {
		gap: 2.5rem;
	}
}

.column {
	inline-size: 100%;
	box-sizing: border-box;
}

@media (--large-devices) {
	.columns {
		flex-direction: row;

		&.columns-reverse {
			flex-direction: row-reverse;
		}

		&.columns-third {
			display: grid;
			grid-template-columns: 1fr 33%;
			gap: 2.5rem;

			.column {
				inline-size: auto;

				&:last-child {
					margin: 0;
					padding: 2.5rem;
				}
			}
		}
	}

	.column {
		inline-size: 50%;
	}
}
