/**
 * Fonts
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

@font-face {
	font-family: poppins;
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
	src:
		local('poppins medium'),
		local('poppins-medium'),
		resolve('fonts/poppins-medium.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
		U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: poppins;
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
	src:
		local('poppins semibold'),
		local('poppins-semibold'),
		resolve('fonts/poppins-semibold.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
		U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: poppins;
	font-weight: 300;
	font-style: normal;
	font-display: fallback;
	src:
		local('poppins light'),
		local('poppins-light'),
		resolve('fonts/poppins-light.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
		U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
	src:
		local('open sans regular'),
		local('opensans-regular'),
		resolve('fonts/opensans-regular.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
		U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
	src:
		local('open sans semibold'),
		local('opensans-semibold'),
		resolve('fonts/opensans-semibold.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
		U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
		U+2212, U+2215, U+FEFF, U+FFFD;
}
