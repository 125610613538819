/**
 * Variables
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>;
 */

@charset "utf-8";

:root {
	/* Font stacks */
	--text-copy: 'Open Sans', sans-serif;
	--text-headline: poppins, sans-serif;

	/* Primary colors */
	--color-primary-900: #0e585c;
	--color-primary-800: #127a87;
	--color-primary-700: #148e9e;
	--color-primary-600: #17a2b8;
	--color-primary-500: #19b2cb;
	--color-primary-400: #2dbdd2;
	--color-primary-300: #4ec8db;
	--color-primary-200: #7fd8e6;
	--color-primary-100: #b1e8f0;
	--color-primary-50: #e0f6f9;

	/* Secondary colors */
	--color-secondary-900: #a5422c;
	--color-secondary-800: #bd4f35;
	--color-secondary-700: #cb553a;
	--color-secondary-600: #d85c3f;
	--color-secondary-500: #e36143;
	--color-secondary-400: #e1795f;
	--color-secondary-300: #e4907a;
	--color-secondary-200: #eaad9f;
	--color-secondary-100: #f1cdc4;
	--color-secondary-50: #f2e1db;

	/* Greyscale colors */
	--color-grey-900: #000;
	--color-grey-800: #424240;
	--color-grey-700: #61615f;
	--color-grey-600: #757573;
	--color-grey-500: #9e9e9c;
	--color-grey-400: #bdbdbb;
	--color-grey-300: #e0e0de;
	--color-grey-200: #eeeeec;
	--color-grey-100: #f5f5f3;
	--color-grey-50: #fafaf8;
	--color-grey-0: #fff;

	/* Text colors */
	--color-text: #333;

	/* Notification colors */
	--color-error: #c10000;

	/* Timing functions */
	--timing-linear: linear;
	--timing-ease-out: ease-out;
	--timing-fast-ease-out: cubic-bezier(0.04, 0.04, 0.12, 0.96);
	--timing-fast-ease-in-ease-out: cubic-bezier(0.52, 0.16, 0.24, 1);
	--timing-bounce: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@custom-media --small-devices-max (width < 639px);
@custom-media --medium-devices (width >= 640px);
@custom-media --medium-devices-max (width < 1023px);
@custom-media --large-devices (width >= 1024px);
@custom-media --large-devices-max (width >= 1440px);
@custom-media --extra-large-devices (width >= 1441px);
@custom-media --supports-hover (hover: hover);
@custom-media --supports-p3 (color-gamut: p3);
