/**
* Markdown Toolbar
* @project Ketterer
* @author  Marc Görtz <https://marcgoertz.de/>
*/

@charset "utf-8";

.toolbar {
	position: absolute;
	inset-block-start: calc(1.375rem + 1px);
	inset-inline: 1px;
	border-start-start-radius: 0.5rem;
	border-start-end-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.25rem 1.25rem;
	background-color: var(--color-grey-200);

	.button-toolbar,
	.button-toolbar:is(:link, :visited, :hover, :focus, :active) {
		padding: 0.25rem;
	}

	a {
		display: inline-flex;
	}

	~ textarea {
		padding-block-start: 2.75rem;
	}
}
