/**
 * Lists
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.plain-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 1.5rem 0;
	padding: 0;
	list-style-type: none;
}

.grid-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 3rem;
	margin: 2.5rem 0 0;
	padding: 0;
	list-style-type: none;
}

.tile-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.25rem;
	margin: 2.5rem 0 0;
	padding: 0;
	font-size: 1.25rem;
	font-weight: 500;
	text-align: center;
	list-style-type: none;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2.5rem;
		color: var(--color-primary-800);
		background-color: var(--color-grey-200);
	}
}

.admin-list {
	a {
		display: block;

		svg {
			fill: var(--color-primary-900);
		}
	}

	.disabled {
		color: var(--color-grey-500);
		cursor: not-allowed;

		svg {
			fill: var(--color-grey-600);
		}
	}

	.card-label {
		cursor: text;
	}
}

.admin-items-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 0 0 1rem;
	padding: 0;
	list-style-type: none;

	li {
		display: grid;
		grid-template-areas: 'link' 'buttons';
		gap: 0.25rem 0.5rem;
		align-items: center;
		padding-block: 0.5rem;

		&:hover {
			background-color: var(--color-grey-50);
		}

		> a {
			grid-area: link;
		}
	}

	.button-group {
		grid-area: buttons;
		justify-content: start;
		flex-wrap: nowrap;
		gap: 0.25rem;
		font-size: 0.875rem;
	}

	@media (--medium-devices) {
		gap: 0;

		li {
			grid-template-areas: 'link buttons';
			grid-template-columns: 1fr max-content;
		}

		.button-group {
			font-size: inherit;
		}
	}

	&.admin-items-sortable {
		.drag-handle {
			grid-area: handle;
			display: inline-block;
			block-size: 1em;
			inline-size: 0.625em;
			color: var(--color-primary-800);
			background-image: radial-gradient(
				circle at center,
				currentcolor 0%,
				currentcolor 50%,
				transparent 51%
			);
			background-size: 0.3125em 0.3125em;
			cursor: move;
		}

		li {
			grid-template-areas: 'link handle' 'buttons handle';
			grid-template-columns: 1fr max-content;
		}

		@media (--medium-devices) {
			li {
				grid-template-areas: 'handle link buttons';
				grid-template-columns: max-content 1fr max-content;
			}
		}
	}
}

.form-list {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
	margin: 2rem 0;
	padding: 0;
	list-style-type: none;

	.checkbox {
		margin-block-start: 1rem;
	}

	.form-control {
		position: relative;
	}

	.form-control-group {
		display: flex;
		flex-direction: column;
		gap: 0.875rem;
	}

	@media (--medium-devices) {
		.form-control-group {
			flex-direction: row;
			gap: 1.25rem;
		}

		.form-control-half {
			flex-basis: 50%;
		}

		.form-control-small {
			flex-basis: 150px;
		}

		.form-control-large {
			flex-basis: calc(100% - 150px);
		}
	}
}

.openingtimes-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		display: flex;
		flex-wrap: wrap;
		gap: 0.25rem 1.25rem;
		margin-block-start: 0.5rem;
	}

	span {
		inline-size: 6em;
	}

	& + p {
		margin-block-start: 1rem;
	}
}

.downloads-list {
	margin: 0;
	padding: 0;
	list-style-type: none;

	.download-pdf {
		padding-inline-start: 1.5rem;
		background-image: resolve('img/downloads.png');
		background-repeat: no-repeat;
		background-position: 0 0.375rem;
	}
}

.member-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: space-between;
	gap: 2.5rem 0.625rem;
	margin: 2rem 0 0;
	padding: 0;
	list-style-type: none;

	li {
		display: flex;
		flex-direction: column;
	}

	h3 {
		margin-block: 2rem 0;
	}

	p {
		margin-block: 0;
		font-size: 1rem;
		hyphens: auto;

		&:nth-last-child(2) {
			font-size: 0.875rem;
			color: var(--color-grey-600);
		}

		&:last-of-type {
			margin-block-start: 0.75rem;
		}
	}

	img {
		flex-shrink: 0;
	}

	@media (--medium-devices) {
		grid-template-columns: repeat(3, 1fr);
		gap: 4.5rem 1.25rem;

		p {
			font-size: 1.125rem;

			&:nth-last-child(2) {
				font-size: 1rem;
			}
		}
	}

	@media (--large-devices) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.job-list {
	margin-block: 0 3rem;
	padding: 0;
	list-style-type: none;
}

.job-contact-image {
	block-size: 120px;
	inline-size: 120px;

	@media (--large-devices) {
		block-size: 200px;
		inline-size: 200px;
	}
}

.news-list {
	& + p {
		margin-block: 2rem 0;
	}
}

.benefit-list {
	margin: 2.5rem 0 0;
	padding: 0;
	list-style-type: none;

	h3 {
		margin-block: 2rem 0.75rem;
	}
}

.workflow-list {
	counter-reset: workflow;
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		counter-increment: workflow;
		position: relative;
		padding-inline-start: 3.625rem;
		padding-block-end: 1.5rem;

		&:not(:last-child)::before {
			content: '';
			position: absolute;
			inset-block: 0;
			inset-inline-start: calc(1.25rem - 1px);
			inline-size: 2px;
			background-color: var(--color-primary-800);
		}

		@media (--large-devices) {
			padding-inline-start: 4.125rem;

			&:not(:last-child)::before {
				inset-inline-start: calc(1.5rem - 1px);
			}
		}
	}

	h3 {
		padding-block-start: 0.25em;
		color: var(--color-primary-800);

		&::before {
			content: counter(workflow);
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 0;
			border-radius: 2em;
			block-size: 2em;
			inline-size: 2em;
			line-height: 2;
			text-align: center;
			color: var(--color-grey-0);
			background-color: var(--color-primary-800);
		}
	}

	p:last-of-type {
		margin-block-end: 0;
	}
}

.offer-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 3rem;
	margin: 2.5rem 0 0;
	padding: 0;
	list-style-type: none;

	li {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	h3 {
		margin-block: 0;
	}

	img {
		block-size: 250px;
		inline-size: 335px;
		object-fit: cover;
	}

	ins {
		display: block;
		font-weight: bold;
		text-decoration: none;
		color: var(--color-error);
	}

	del {
		display: block;
		font-size: 1rem;
		color: var(--color-grey-600);
	}
}

@media (--large-devices) {
	.grid-list {
		margin-block-start: 5rem;
		grid-template-columns: repeat(3, 1fr);

		&.grid-list-4 {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	.tile-list {
		grid-template-columns: repeat(3, 1fr);
		font-size: 1.5rem;

		li {
			min-block-size: 10em;
		}
	}

	.offer-list {
		grid-template-columns: repeat(3, 1fr);
		gap: 5rem;
	}

	.benefit-list {
		svg {
			block-size: 5rem;
			inline-size: 5rem;
		}
	}
}
