/**
* Typography
* @project Ketterer
* @author  Marc Görtz <https://marcgoertz.de/>
*/

@charset "utf-8";

html {
	font-family: var(--text-copy);
	line-height: 1.5;
}

body {
	font-size: 1.125rem;
}

/* Headlines */
:is(h1, h2, h3, h4, h5, h6, .like-h2, .like-h3, .like-h4) {
	font-family: var(--text-headline);
}

h1 {
	margin: 0 0 0.5rem;
	font-size: 1.75rem;
	font-weight: 500;
	line-height: 1.2;
	letter-spacing: -0.02em;

	@media (--large-devices) {
		margin-block-end: 1rem;
		font-size: 2.25rem;
		font-weight: 600;
		line-height: 1.25;
		letter-spacing: -0.02em;

		&.deco {
			font-size: 3rem;
			font-weight: 500;
			line-height: 1.2;
		}
	}
}

h2,
.like-h2 {
	margin: 0 0 0.5rem;
	font-size: 1.375rem;
	font-weight: 600;
	line-height: 1.2727;

	@media (--large-devices) {
		margin-block-end: 1rem;
		font-size: 1.625rem;
		line-height: 1.25;

		&.deco {
			font-size: 2.5rem;
			font-weight: 500;
			line-height: 1.2;
			letter-spacing: -0.02em;
		}
	}
}

h3,
.like-h3 {
	margin: 0 0 0.5rem;
	font-size: 1.25rem;
	font-weight: 500;

	@media (--large-devices) {
		margin-block-end: 1rem;
		font-size: 1.5rem;
		line-height: 1.25;
	}
}

h4,
.like-h4 {
	margin: 0 0 0.5rem;
	font-size: 1.125rem;
	font-weight: 600;
	line-height: 1.25;

	@media (--large-devices) {
		margin-block-end: 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
	}
}

/* Paragraphs */
p {
	margin: 0 0 1rem;

	&.intro {
		font-size: 1.25rem;

		@media (--large-devices) {
			font-size: 1.5rem;
		}
	}
}

/* Quotes */
blockquote {
	margin: 0 auto 1rem;
	max-inline-size: 34em;
	font-family: var(--text-headline);
	font-size: 1.5rem;
	font-weight: 300;
	font-style: normal;
	text-align: center;
	color: var(--color-primary-900);
}

cite {
	display: flex;
	align-items: center;
	gap: 1.25em;
	margin: 2.5rem auto 1rem;
	max-inline-size: 28.75rem;
	font-size: 1rem;
	font-style: normal;
	text-align: start;

	strong {
		display: block;
		font-size: 1.25em;
		font-weight: 600;
	}

	@media (--large-devices) {
		gap: 2.5rem;
	}
}

/* Abbreviations */
abbr[title] {
	border: 0;
	text-decoration: none;
	cursor: help;
}

/* sub and sup */
sub,
sup {
	position: relative;
	font-size: 66.6666%;
	line-height: 0;
	vertical-align: baseline;
}

sup {
	inset-block-start: -0.5em;
}

sub {
	inset-block-end: -0.25em;
}

.no-break {
	white-space: nowrap;
}

.align-end {
	text-align: end;
}

.align-center {
	text-align: center;
}

.align-center-small {
	margin-block-end: 0.5em;
	text-align: center;

	@media (--large-devices) {
		text-align: unset;
	}
}
