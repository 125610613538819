/**
 * Header
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

body > header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	margin-inline: auto;
	padding: 1.25rem clamp(1.25rem, (100vw - 1240px) / 2, 21.25rem);
	max-inline-size: 1920px;
	background-color: var(--color-grey-0);
	box-sizing: border-box;

	@media (--large-devices) {
		padding-block-end: 0;
	}

	a:focus-visible {
		outline: 2px solid var(--color-primary-900);
		outline-offset: 2px;
	}
}

body > header .logo {
	a {
		display: block;
	}

	svg {
		block-size: auto;
		inline-size: 200px;

		@media (--medium-devices) {
			inline-size: 240px;
		}

		@media (--large-devices) {
			inline-size: 300px;
		}
	}
}

@media (--large-devices) {
	body > header {
		align-items: end;
	}
}
