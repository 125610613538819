/**
 * Footer
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

button,
.button {
	&,
	&:is(:link, :visited, :hover, :focus, :active) {
		position: relative;
		display: inline-block;
		padding: 0.8889em 1.3333em;
		border: 0;
		border-radius: 0;
		text-decoration: none;
		text-align: center;
		font-family: inherit;
		font-size: inherit;
		font-weight: 600;
		line-height: 1.25;
		cursor: pointer;

		&[disabled],
		&[aria-disabled='true'] {
			opacity: 0.4;
			cursor: not-allowed;
		}

		&:focus-visible {
			outline: 2px solid var(--color-secondary-500);
			outline-offset: 2px;
		}
	}

	& + button:not(.button-toolbar),
	& + .button:not(.button-toolbar) {
		margin-inline-start: 15px;
	}

	&.button-primary {
		color: var(--color-grey-900);
		background-color: var(--color-secondary-300);

		&:hover {
			background-color: var(--color-secondary-200);
		}
	}

	&.button-secondary {
		color: var(--color-grey-0);
		background-color: var(--color-primary-800);

		&:hover {
			background-color: var(--color-primary-700);
		}
	}

	&.button-tertiary {
		color: var(--color-grey-900);
		background-color: var(--color-grey-200);

		&:hover {
			background-color: var(--color-grey-300);
		}
	}

	&.button-quartiary {
		color: var(--color-grey-900);
		background-color: var(--color-grey-0);

		&:hover {
			background-color: var(--color-grey-100);
		}
	}

	&.button-toolbar {
		padding: 0.25rem;
	}

	&.button-delete {
		color: var(--color-grey-0);
		background-color: var(--color-error);

		&:hover {
			background-color: var(--color-error);
		}
	}

	svg {
		block-size: 1.25rem;
		inline-size: 1.25rem;
		fill: currentcolor;
	}
}

.button-group {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1rem;

	.button + .button {
		margin-inline-start: 0;
	}

	&.button-group-start {
		justify-content: flex-start;
	}
}
