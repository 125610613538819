/**
 * Layout
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

/* images */
img {
	color: inherit;
	background: transparent;
	vertical-align: bottom;
	max-inline-size: 100%;
	block-size: auto;
	border: 0; /* removes border when inside `a` element in IE 8/9/10 */

	&.rounded {
		border-radius: 100%;
	}
}

/* SVG images */
svg {
	max-inline-size: 100%;
	block-size: auto;
	vertical-align: bottom;
}

svg:not(:root) {
	overflow: hidden; /* correct overflow not hidden in IE 9/10/11 */
}

/* videos */
video {
	max-inline-size: 100%;
	block-size: auto;
	vertical-align: bottom;
}

/* image groups */
.image-group {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	margin: 0;

	img {
		align-self: center;
	}
}

figure {
	margin: 0;

	& + & {
		margin-block-start: 1rem;
	}
}

@media (--medium-devices) {
	.image-group {
		img {
			max-inline-size: 335px;
		}
	}

	.image-group-end {
		gap: 0;

		img {
			align-self: end;
		}
	}
}

@media (--large-devices) {
	.image-group {
		align-items: center;
		justify-content: space-between;
		flex-direction: row;
		gap: 5.5rem;

		img {
			max-inline-size: 440px;
			margin-inline-start: 5.5rem;
		}

		figcaption {
			max-inline-size: 540px;
		}
	}

	.image-group-end {
		flex-direction: row-reverse;

		img {
			display: block;
			margin-inline-end: 5.5rem;
			max-inline-size: 100%;
		}
	}
}
