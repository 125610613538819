/**
 * Disclosure
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

details {
	margin-block-start: 1.25rem;
	padding-block-start: 1.25rem;
	border-block-start: 1px solid #cfd6dc;
	overflow: hidden;

	&:first-of-type {
		margin-block-start: 2rem;
		padding-block-start: 0;
		border-block-start: 0;
	}

	summary {
		position: relative;
		padding-inline-end: 2.5rem;
		font-family: var(--text-headline);
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5;
		list-style-type: none;
		cursor: pointer;

		&::marker,
		&::-webkit-details-marker {
			display: none;
		}

		&::after {
			content: '';
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			inset-block-start: calc(50% - 1rem);
			inset-inline-end: 0;
			block-size: 2rem;
			inline-size: 2rem;
			line-height: 1;
			background-color: currentcolor;
			mask-image: inline('icons/disclosure-open.svg');
			mask-size: contain;
			transform: none;
			transition: transform 0.2s;
			transform-origin: center;
		}
	}

	&[open] {
		summary {
			margin-block-end: 1rem;

			&::after {
				transform: scale(-1);
			}
		}
	}
}
