/**
* Tables
* @project Ketterer
* @author  Marc Görtz <https://marcgoertz.de/>
*/

@charset "utf-8";

table {
	margin: 0 0 1rem;
	inline-size: 100%;

	th,
	td {
		padding: 0.5rem 0;
		text-align: start;
	}

	th[scope='row'] {
		inline-size: 85px;
		font-weight: normal;
		vertical-align: top;
	}

	tr:hover td {
		background-color: var(--color-grey-50);
	}
}
