/**
 * Form
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

input,
textarea,
select {
	margin: 0;
	padding: 1.25rem;
	border: 1px solid var(--color-grey-500);
	border-radius: 0.5rem;
	outline: 0;
	inline-size: 100%;
	font-size: 1rem;
	font-family: inherit;
	text-align: start;
	color: var(--color-grey-900);
	background-color: var(--color-grey-0);
	accent-color: var(--color-primary-600);
	box-sizing: border-box;

	&::placeholder {
		color: var(--color-grey-600);
		opacity: 1;
	}

	&:hover {
		border-color: var(--color-grey-600);
	}

	&:focus {
		border-color: var(--color-primary-600);
	}

	&:focus-visible {
		outline: 1px solid var(--color-primary-600);
	}

	&:disabled {
		color: var(--color-grey-400);
		border-color: var(--color-gray-400);
		cursor: not-allowed;
	}

	&[aria-invalid='true'],
	&:user-invalid {
		border: 1px solid var(--color-error);
		box-shadow: none;

		&:hover,
		&:focus {
			border-color: var(--color-error);
		}

		&:focus-visible {
			outline: 1px solid var(--color-error);
		}
	}

	~ .description {
		display: block;
		margin: 0.5rem 0 0;
		font-size: 0.75em;

		code {
			padding: 0;
			font-family: monospace;
			color: var(--color-primary-800);
		}
	}
}

input {
	&[type='file'] {
		inline-size: auto;
		padding: 0;
		border: 0;
	}

	/* date inputs */
	&[type^='date'] {
		min-block-size: 50px; /* fix inputs with an empty value */
		appearance: none; /* remove ugly iOS Safari date input look */

		&::-webkit-date-and-time-value {
			text-align: start; /* fix value alignment in iOS Safari 15 */
		}
	}

	&[type='checkbox'] {
		inline-size: auto;
		border: initial;
		cursor: pointer;
	}

	&[aria-invalid='true'],
	&:user-invalid {
		padding-inline-end: 2.75rem;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23c10000'%3E%3Cpath d='M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667m.833 12.5H9.167V12.5h1.666zm0-3.334H9.167v-5h1.666z'/%3E%3C/svg%3E");
		background-size: 1rem;
		background-repeat: no-repeat;
		background-position: right 1rem center;
	}
}

.dropdown {
	display: block;
	position: relative;

	output {
		display: inherit;
		padding: 1.25rem 2.75rem 1.25rem 1.25rem;
		border: 1px solid var(--color-grey-500);
		border-radius: 0.5rem;
		outline: 0;
		inline-size: 100%;
		font-size: 1rem;
		font-family: inherit;
		text-align: start;
		color: var(--color-grey-900);
		background-color: var(--color-grey-0);
		box-sizing: border-box;

		&::after {
			content: '';
			position: absolute;
			inset-block-end: 1.5rem;
			inset-inline-end: 1rem;
			border: 2px solid;
			border-block-start: 0;
			border-inline-end: 0;
			block-size: 10px;
			inline-size: 10px;
			transform: rotate(-45deg) translateY(-50%);
			pointer-events: none;
		}
	}

	select {
		position: absolute;
		inset: 0;
		z-index: 1;
		block-size: 100%;
		cursor: pointer;
		opacity: 0;
		appearance: none;

		&:hover {
			& + output {
				border-color: var(--color-grey-600);
			}
		}

		&:focus {
			& + output {
				border-color: var(--color-primary-600);
			}
		}

		&:focus-visible {
			& + output {
				outline: 1px solid var(--color-primary-600);
			}
		}

		&[aria-invalid='true'] {
			& + output {
				border: 1px solid var(--color-error);

				&::after {
					inset-block-end: 1.5rem;
					border: 0;
					block-size: 1rem;
					inline-size: 1rem;
					background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23c10000'%3E%3Cpath d='M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667m.833 12.5H9.167V12.5h1.666zm0-3.334H9.167v-5h1.666z'/%3E%3C/svg%3E");
					background-size: 1rem;
					background-repeat: no-repeat;
					background-position: center;
					transform: unset;
				}
			}

			&:hover,
			&:focus {
				& + output {
					border-color: var(--color-error);
				}
			}

			&:focus-visible {
				& + output {
					outline: 1px solid var(--color-error);
				}
			}
		}
	}

	.form-control-empty & {
		output {
			color: var(--color-grey-600);
		}
	}
}

/* Labels */
label {
	display: block;
	margin-block-end: 0.25rem;
	font-size: 0.75rem;
	color: var(--color-grey-600);

	input[type='checkbox'] + &,
	input[type='radio'] + & {
		display: inline; /* for labels next to checkboxes or radio boxes */
		color: var(--color-grey-0);
	}

	&.checkbox {
		position: relative;
		display: flex;
		align-items: start;
		gap: 0.5rem;
		font-size: 1rem;

		span {
			padding-inline-start: 2rem;

			&::before {
				content: '';
				position: absolute;
				inset-block-start: 0;
				inset-inline-start: 0;
				border: 2px solid var(--color-grey-800);
				border-radius: 0.1875rem;
				box-sizing: border-box;
				block-size: 1.5rem;
				inline-size: 1.5rem;
				background-color: var(--color-grey-0);
				cursor: pointer;
			}
		}

		input {
			position: absolute;
			margin: -1px;
			padding: 0;
			border: 0;
			block-size: 1px;
			inline-size: 1px;
			overflow: hidden;
			clip: rect(0, 0, 0, 0);

			&:focus + span::before,
			&:hover + span::before {
				border-color: var(--color-primary-600);
			}

			&:checked + span {
				&::before {
					border-color: var(--color-primary-600);
					background-color: var(--color-primary-600);
				}

				&::after {
					content: '';
					position: absolute;
					inset-block-start: 0;
					inset-inline-start: 0;
					block-size: 1.5rem;
					inline-size: 1.5rem;
					background-color: var(--color-grey-0);
					mask-image: inline('icons/check.svg');
					mask-size: contain;
					pointer-events: none;
				}
			}

			&:disabled {
				& + span::before,
				&:focus + span::before,
				&:hover + span::before {
					border-color: var(--color-grey-800);
					opacity: 0.4;
					cursor: not-allowed;
				}

				&:checked + span::before {
					background-color: var(--color-grey-800);
				}
			}
		}
	}

	&.disabled {
		color: var(--color-grey-400);
	}

	&.error {
		margin-block: 0.25rem 0;
		color: var(--color-error);
		cursor: pointer;

		/* Unset floating label styles */
		position: unset !important;
		opacity: unset !important;
		transform: unset !important;
		transition: unset !important;
	}
}

/* Floating labels */
.form-control {
	padding: 1.25rem 0 0;

	& > label {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		margin-block-end: 0;
		opacity: 0;
		transform: translate(1rem, 1rem) scale(1.25);
		transform-origin: left;
		transition: all 0.2s var(--timing-fast-ease-out);
	}

	&.form-control-filled {
		& > label {
			opacity: 1;
			transform: translate(0, 0) scale(1);
		}
	}

	&.form-control-alternate {
		&.form-control-empty {
			input,
			textarea {
				border-color: var(--color-grey-50);
				background-color: var(--color-grey-50);

				&:hover {
					border-color: var(--color-grey-100);
					background-color: var(--color-grey-100);
				}

				&:focus {
					background-color: var(--color-grey-0);
				}
			}

			.dropdown {
				output {
					border-color: var(--color-grey-50);
					background-color: var(--color-grey-50);
				}

				select {
					&:hover {
						& + output {
							border-color: var(--color-grey-100);
							background-color: var(--color-grey-100);
						}
					}

					&:focus {
						& + output {
							border-color: var(--color-primary-600);
							background-color: var(--color-grey-0);
						}
					}
				}
			}
		}
	}
}

/* Uploaded file preview */
.uploaded {
	display: block;
	margin: 3px 0;
}

/* Form errors or required form fields */
[role='alert'] {
	font-weight: bold;
	color: var(--color-error);
}

/* Groups */
.group {
	h3 {
		display: flex;
		align-items: center;

		span {
			flex-grow: 1;
		}

		button {
			flex-grow: 0;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
}

/* Messages */
.message {
	display: flex;
	gap: 1ch;
	padding: 1rem;
	border: 1px solid var(--color-primary-100);
	color: var(--color-text);
	background: var(--color-primary-50);
}

/* Password strength */
.password-strength {
	position: absolute;
	margin: -1px;
	padding: 0;
	border: 0;
	inline-size: 1px;
	block-size: 1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
}

.password-strength-text {
	display: inline-block;
	margin-inline-start: 0.5em;
	padding: 0 1em;
	border-radius: 1em;
	color: var(--color-grey-0);

	&.password-strength-text-weak {
		background-color: hsl(from var(--color-error) h s 60%);
	}

	&.password-strength-text-medium {
		background-color: var(--color-secondary-500);
	}

	&.password-strength-text-strong {
		background-color: hsl(from var(--color-primary-500) h s 40%);
	}
}
