/**
 * Footer
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

footer {
	margin-inline: auto;
	padding: 2.5rem clamp(1.25rem, (100vw - 1240px) / 2, 21.25rem);
	max-inline-size: 1920px;
	box-sizing: border-box;
	color: var(--color-grey-0);
	background-color: var(--color-primary-900);
	font-size: 1rem;

	h2 {
		margin-block-end: 1rem;
		font-size: 1.25rem;
		font-weight: 600;
		line-height: 1.5;
	}

	p {
		margin: 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;

		li {
			margin-block-start: 1rem;

			&:first-of-type {
				margin-block-start: 0;
			}
		}
	}

	a:is(:link, :visited, :hover, :focus, :active) {
		color: inherit;
		font-weight: normal;
	}
}

.footer-disclosure {
	border-block-start: 1px solid rgb(255 255 255 / 10%);

	summary {
		padding-inline-end: 1.5rem;

		&::after {
			inset-block-start: calc(50% - 0.625rem);
			block-size: 1.25rem;
			inline-size: 1.25rem;
		}
	}

	&:first-of-type {
		margin-block-start: 2.5rem;
	}
}

.footer-content {
	margin-block-start: 2.5rem;

	h2 {
		margin-block-end: 2.5rem;
	}
}

.footer-logo {
	grid-area: logo;

	svg {
		inline-size: 200px;
		block-size: auto;
	}

	path:first-of-type {
		fill: currentcolor;
	}
}

.footer-address {
	grid-area: address;

	h2 {
		margin-block-end: 1rem;
	}

	svg {
		block-size: 1.25rem;
		inline-size: 1.25rem;
		fill: currentcolor;
	}

	a:is(:link, :visited, :hover, :focus, :active) {
		display: flex;
		align-items: center;
		gap: 0.75rem;
		margin-block-start: 1rem;
		padding: 0.875rem;
		font-weight: 600;
		background-color: rgb(0 0 0 / 10%);
	}
}

.footer-popular {
	grid-area: popular;
}

.footer-info {
	grid-area: info;
}

.footer-openingtimes {
	grid-area: openingtimes;

	li {
		margin-block-start: 1rem;
	}
}

.footer-certificates {
	grid-area: certificates;

	p {
		display: flex;
		gap: 1rem;
	}
}

.footer-memberships {
	grid-area: memberships;
}

.footer-socials {
	grid-area: socials;
	padding-block-start: 2.5rem;
	border-block-start: 1px solid rgb(255 255 255 / 10%);
	fill: currentcolor;

	ul {
		display: flex;
		gap: 1.25rem;
		margin-block-start: -1.5rem;

		li {
			margin-block-start: 0;
		}
	}
}

.footer-legal {
	grid-area: legal;
	padding-block-start: 2.5rem;
	border-block-start: 1px solid rgb(255 255 255 / 10%);

	.footer-socials ~ & {
		padding-block-start: 0;
		border-block-start: 0;
	}
}

.footer-copyright {
	grid-area: copyright;
	margin-block-start: 1rem;
	font-size: 0.875rem;
}

@media (--medium-devices) {
	footer {
		display: grid;
		grid-template-areas: 'logo logo' 'address popular' 'info openingtimes' 'certificates memberships' 'legal legal' 'copyright copyright';
		gap: 3rem 1.25rem;
		padding-block: 5rem;
	}

	.footer-content {
		margin-block-start: 0;
	}

	.js .footer-disclosure,
	.js .footer-disclosure:first-of-type {
		margin-block-start: 0;
		padding-block-start: 0;
		border-block-start: 0;

		summary {
			padding-inline-end: unset;
			cursor: unset;

			&::after {
				display: none;
			}
		}
	}

	.footer-legal {
		margin-block-end: -3rem;

		ul {
			display: flex;
			flex-wrap: wrap;
			gap: 1.25rem;

			li {
				margin-block-start: 0;
			}
		}
	}
}

@media (--large-devices) {
	footer {
		grid-template-areas: 'logo logo logo logo' 'address popular info openingtimes' '. certificates memberships .' 'legal legal legal legal' 'copyright copyright copyright copyright';
		grid-template-columns: repeat(4, 0.25fr);
		gap: 3rem;
	}

	.footer-legal {
		display: flex;
		justify-content: end;

		.footer-socials ~ & {
			padding-block-start: 2.5rem;
			border-block-start: 1px solid rgb(255 255 255 / 10%);
		}
	}

	.footer-copyright {
		text-align: end;
	}
}
