/**
 * Map
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.map {
	margin: 2.5rem 0 0;
	inline-size: 100%;
	block-size: 300px;
	border: 1px solid var(--color-grey-300);
	color: var(--color-text);
	background: transparent;
	text-align: center;
}

/* images in Google Maps */
.gm-style img {
	max-inline-size: none;
}

.contact-label {
	display: inline-block;
	min-inline-size: 4em;
}
