/**
 * Navigation
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset 'utf-8';

/* toggle button */
.main-nav button,
.main-nav button:is(:hover, :focus, :active) {
	position: absolute;
	inset-block-start: 1rem;
	inset-inline-end: 0.75rem;
	z-index: 101;
	padding: 0;
	block-size: 3em;
	inline-size: 3em;
	font-size: 1rem;
	text-decoration: none;
	color: inherit;
	background: none;
}

.main-nav button {
	&::before {
		content: 'Menü';
		position: absolute;
		inset-block-start: 50%;
		inset-inline-end: 100%;
		font-size: 0.875em;
		color: var(--color-grey-700);
		transform: translateY(-50%);
	}

	span {
		position: absolute;
		inset: 0.5em;
		transform: none;
		transition: transform 0.2s var(--timing-fast-ease-out);

		&::before {
			content: '';
			position: absolute;
			inset-inline: 0.25em;
			block-size: 2.5px;
			background-color: currentcolor;
			transition: all 0.15s var(--timing-fast-ease-in-ease-out) 0.2s;
		}

		&:nth-child(1)::before {
			inset-block-start: calc(2em / 2 - 2.5px / 2);
			transform: translateY(-6.5px);
		}

		&:nth-child(2)::before {
			inset-block-end: calc(2em / 2 - 2.5px / 2);
		}

		&:nth-child(3)::before {
			inset-block-end: calc(2em / 2 - 2.5px / 2);
			transform: translateY(6.5px);
		}
	}
}

/* navigation list */
.nav-list {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	position: fixed;
	inset-block: 0;
	inset-inline-start: calc(100% + 1.25rem);
	z-index: 100;
	margin: 0;
	padding: 5rem 1.25rem 2rem;
	inline-size: 80%;
	color: var(--color-text);
	background-color: var(--color-grey-0);
	text-align: end;
	list-style: none;
	box-sizing: border-box;
	transition: 0.4s transform var(--timing-fast-ease-out);
	box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.nav-list a:is(:link, :visited, :hover, :focus, :active) {
	display: inline-block;
	text-decoration: none;
	font-weight: normal;
	white-space: nowrap;
	color: inherit;
}

.nav-list a.active {
	color: var(--color-primary-600);
	font-weight: 600;
}

/* visible navigation */
.show-navigation {
	.main-nav button {
		position: fixed;

		&::before {
			content: '';
		}

		span {
			&:nth-child(1) {
				transform: rotate(45deg);
				transition: transform 0.3s var(--timing-fast-ease-out) 0.2s;

				&::before {
					transform: none;
					transition: transform 0.2s var(--timing-fast-ease-out);
				}
			}

			&:nth-child(2)::before {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
				transition: transform 0.3s var(--timing-fast-ease-out) 0.2s;

				&::before {
					transform: none;
					transition: transform 0.2s var(--timing-fast-ease-out);
				}
			}
		}
	}

	.nav-list {
		transform: translateX(calc(-100% - 1.25rem));
	}

	/* backdrop */
	body::after {
		content: '';
		position: fixed;
		inset: 0;
		z-index: 99;
		background-color: rgb(0 0 0 / 50%);
		backdrop-filter: blur(3px);
		pointer-events: none;
	}
}

/* lava */
.lava {
	display: none;
}

@media (--medium-devices) {
	.main-nav button,
	.main-nav button:is(:hover, :focus, :active) {
		inset-block-start: 1.25rem;
	}

	.nav-list {
		inline-size: 40%;
	}
}

@media (--large-devices) {
	.main-nav {
		position: relative;
	}

	.main-nav button,
	.main-nav button:is(:hover, :focus, :active) {
		display: none;
	}

	.nav-list {
		position: static;
		flex-direction: row;
		padding: 0 1.5rem;
		inline-size: auto;
		box-shadow: none;
		text-align: start;
		transform: none;
		transition: none;
	}

	.nav-list a:is(:link, :visited, :hover, :focus, :active) {
		padding-block-end: 1.25rem;
		padding-inline: 0.75rem;
	}

	.nav-list a:hover {
		border-block-end: 5px solid var(--color-grey-400);
	}

	.nav-list a.active {
		border-block-end: 5px solid var(--color-primary-600);
		font-weight: normal;
	}

	.lava {
		display: block;
		position: absolute;
		inset-block-end: 0;
		inset-inline-start: 0;
		z-index: 99;
		border-block-end: 5px solid var(--color-grey-400);
		pointer-events: none;
		transition: color 0.2s;

		&.active {
			border-block-end-color: var(--color-primary-600);
		}
	}

	.show-navigation {
		overflow-y: unset;

		.nav-list {
			transform: none;
		}

		body::after {
			display: none;
		}
	}

	.js {
		.nav-list a:hover,
		.nav-list a.active {
			border-block-end: 0;
		}
	}
}
