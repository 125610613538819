/**
 * Card
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

.card {
	position: relative;
	padding: 1.875rem;
	background-color: var(--color-grey-0);
	box-shadow: 0 0 1rem #00000014;

	& > p:last-child {
		margin-block-end: 0;
	}
}

.card-image {
	margin: -1.875rem -1.875rem 1.875rem;

	img {
		block-size: 200px;
		inline-size: 100%;
		max-inline-size: none;
		object-fit: cover;
	}

	&.card-image-fixed {
		img {
			block-size: 150px;
			object-position: top;
		}
	}
}

.card-label {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0.5rem;
	padding: 0.125rem 0.5rem;
	border-radius: 1rem;
	font-size: 0.875rem;
	color: var(--color-grey-0);
	background-color: var(--color-secondary-500);
	transform: translateY(-50%);
}

.card-alternate {
	border-radius: 0.5rem;
	color: var(--color-grey-0);
	background-color: var(--color-primary-700);
}

.cards {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2.5rem 1.25rem;
	justify-content: space-between;
	margin-block-start: 2.5rem;
}

@media (--medium-devices) {
	.cards {
		display: grid;
		grid-template-columns: repeat(2, minmax(270px, 1fr));
	}
}

@media (--large-devices) {
	.cards {
		grid-template-columns: repeat(3, minmax(270px, 1fr));
	}

	.card-image {
		img {
			block-size: auto;
			object-fit: fill;
		}

		&.card-image-fixed {
			img {
				block-size: 200px;
				object-fit: cover;
				object-position: top;
			}
		}
	}
}
