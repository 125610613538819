/**
* Typography
* @project Ketterer
* @author  Marc Görtz <https://marcgoertz.de/>
*/

@charset "utf-8";

.entry-title {
	grid-area: title;
}

.entry-link {
	margin-block-start: 1rem;
}

.entry-video {
	grid-area: aside-start;
}

.entry-image {
	grid-area: aside-start;

	.entry-video ~ & {
		grid-area: aside-end;
	}
}

.entry-meta {
	grid-area: meta;
	font-size: 1rem;
}

.entry-content {
	grid-area: content;
	hyphens: auto;

	> *:first-child:not(h1, h2, h3, h4, h5, h6, :only-child),
	> *:is(h1, h2, h3, h4, h5, h6) + *:not(:only-child):first-child {
		font-size: 1.25rem;

		@media (--large-devices) {
			font-size: 1.5rem;
		}
	}
}

.news-details-grid {
	display: grid;
	grid-template-areas: 'title' 'meta' 'aside-start' 'content' 'aside-end';
	gap: 1rem;
}

@media (--large-devices) {
	.news-details-grid {
		grid-template-areas: 'title title' 'meta meta' 'content aside-start' 'content aside-end';
		grid-template-columns: 1fr 400px;
		gap: 1rem 2.5rem;
	}
}
