/**
 * Footer
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

a:is(:link, :visited, :hover, :focus, :active) {
	color: var(--color-primary-800);
	font-weight: 600;
	text-rendering: auto;
}

a:is(:link, :visited) {
	text-decoration: none;
}

a:is(:hover, :focus, :active) {
	text-decoration: underline;
}

/* Backlink */
.backlink {
	font-size: 0.875rem;
	color: var(--color-grey-600);

	svg {
		fill: currentcolor;
		vertical-align: middle;
	}

	a {
		color: inherit;
	}
}
