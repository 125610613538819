/**
 * Layout
 * @project Ketterer
 * @author  Marc Görtz <https://marcgoertz.de/>
 */

@charset "utf-8";

html {
	color: var(--color-text);
	background-color: var(--color-grey-0);
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	margin: 0;
}

main {
	margin: 0 auto;
	padding: 0;
	max-inline-size: 1920px;
	overflow-x: hidden;

	& > section {
		padding: 2.5rem clamp(1.25rem, (100vw - 1240px) / 2, 21.25rem);

		@media (--large-devices) {
			padding-block: 5rem;
		}
	}
}

.teaser-standard {
	padding-block: 2.5rem 3.75rem;

	@media (--medium-devices) {
		padding-block: 6.25rem;
	}
}

.teaser-dark {
	color: var(--color-grey-0);
	background-color: var(--color-primary-900);
}

.teaser-light {
	background-color: var(--color-primary-50);
	fill: var(--color-primary-900);

	h3 {
		color: var(--color-primary-900);
	}
}

.teaser-orange {
	background-color: var(--color-secondary-50);
}

.teaser-dark-grey {
	background-color: var(--color-grey-200);
}

.teaser-light-grey {
	background-color: var(--color-grey-100);
}

.teaser-ghost {
	background-color: var(--color-grey-50);
}

.teaser-limited {
	> * {
		max-inline-size: 52rem;
	}
}

.teaser-combined {
	padding-block-start: 0;
}

.teaser-stage {
	padding: 0;
	align-items: center;

	h1 {
		hyphens: auto;
	}

	img {
		block-size: 230px;
		inline-size: 100%;
		object-fit: cover;

		@media (--medium-devices) {
			block-size: 320px;
		}

		@media (--large-devices) {
			block-size: 100%;
			max-block-size: 720px;
			inline-size: 100%;
		}
	}

	.column {
		&:first-child {
			inline-size: auto;
			block-size: 100%;
		}

		&:last-child {
			padding: 1.5rem 1.25rem;
			inline-size: auto;

			@media (--medium-devices) {
				padding: 2.5rem;
			}

			@media (--large-devices) {
				padding: 5rem;
			}
		}
	}

	&.columns {
		display: grid;
		grid-template: 1fr / 1fr;

		@media (--large-devices) {
			grid-template: 1fr / repeat(2, 50%);
			align-items: center;
		}
	}

	@media (--large-devices) {
		&.columns-reverse {
			.column:last-child {
				order: -1;
			}
		}
	}
}

.teaser-form {
	display: grid;
	grid-template-areas: 'image' 'form';
	align-items: center;
	padding: 0;

	img {
		grid-area: image;
		object-fit: cover;
		inline-size: 100%;
		block-size: 100%;
	}

	form {
		grid-area: form;
		padding: 1.25rem;
	}

	@media (--medium-devices) {
		img {
			max-block-size: 320px;
		}
	}

	@media (--large-devices) {
		grid-template-areas: 'image form';
		grid-template-columns: repeat(2, 50%);

		img {
			max-block-size: none;
		}

		form {
			padding: 5rem;
		}
	}
}

.teaser-group {
	display: flex;
	flex-direction: column;

	@media (--small-devices-max) {
		padding: 0;
	}

	@media (--medium-devices) {
		padding-block: 5rem;
	}

	@media (--large-devices) {
		flex-direction: row;
		padding-block: 6.25rem;
	}

	section {
		padding: 2.25rem 1.25rem 1.75rem;

		@media (--large-devices) {
			padding: 5rem;
			inline-size: 50%;
			box-sizing: border-box;
		}
	}
}

.relative {
	position: relative;
}
